import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const UpdatePassword = () => import('@/views/UpdatePassword')
const CreateAdmin = () => import('@/views/admins/Create')
const EditAdmin = () => import('@/views/admins/Edit')
const ListAdmin = () => import('@/views/admins/List')

const CreateCashtag = () => import('@/views/cashtags/Create')
const EditCashtag = () => import('@/views/cashtags/Edit')
const ListCashtag = () => import('@/views/cashtags/List')

const EditGame = () => import('@/views/games/Edit')
const ListGame = () => import('@/views/games/List')

const CreateSite = () => import('@/views/sites/Create')
const EditSite = () => import('@/views/sites/Edit')
const ListSite = () => import('@/views/sites/List')

const ListTransaction = () => import('@/views/transactions/List')
const CreditTransferProvider = () => import('@/views/transactions/CreditTransferProvider')
const CashappDeposits = () => import('@/views/transactions/CashappDeposits')

// Views - Pages
const Login = () => import('@/views/Login')

Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      meta: {
        requiresAuth: true,
      },
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'update-password',
          name: 'Update Password',
          component: UpdatePassword
        },
        {
          path: 'admins',
          redirect: '/admins/list',
          name: 'Admins',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Admin',
              component: ListAdmin
            },
            {
              path: 'create',
              name: 'Create Admin',
              component: CreateAdmin
            },
            {
              path: 'edit/:id',
              name: 'Edit Admin',
              component: EditAdmin
            }
          ]
        },
        {
          path: 'cashtags',
          redirect: '/cashtags/list',
          name: 'Cashtags',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Cashtag',
              component: ListCashtag
            },
            {
              path: 'create',
              name: 'Create Cashtag',
              component: CreateCashtag
            },
            {
              path: 'edit/:id',
              name: 'Edit Cashtag',
              component: EditCashtag
            }
          ]
        },
        {
          path: 'games',
          redirect: '/games/list',
          name: 'Games',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Game',
              component: ListGame
            },
            {
              path: 'edit/:id',
              name: 'Edit Game',
              component: EditGame
            }
          ]
        },
        {
          path: 'sites',
          redirect: '/sites/list',
          name: 'Sites',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Site',
              component: ListSite
            },
            {
              path: 'create',
              name: 'Create Site',
              component: CreateSite
            },
            {
              path: 'edit/:id',
              name: 'Edit Site',
              component: EditSite
            }
          ]
        },
        {
          path: 'transactions',
          redirect: '/transactions/list',
          name: 'Transactions',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Transaction',
              component: ListTransaction
            },
            {
              path: 'credit-transfer-provider',
              name: 'Credit Transfer Provider',
              component: CreditTransferProvider
            },
            {
              path: 'cashapp-deposits',
              name: 'Cashapp Deposits',
              component: CashappDeposits
            }
          ]
        }
        
      ]
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        requiresAuth: false,
      },
      component: Login
      
    }
  ]
}

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (window.localStorage.getItem('authToken')) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router